@font-face {
  font-family: Roboto;
  src: url('../font/Roboto-VariableFont.woff2') format('woff2'),
  url('../font/Roboto-VariableFont.woff') format('woff'),
  url('../font/Roboto-VariableFont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: JDZhengHT-EN;
  src: url('../font/JDZhengHT-EN-Regular.woff2') format('woff2'),
  url('../font/JDZhengHT-EN-Regular.woff') format('woff'),
  url('../font/JDZhengHT-EN-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  background-color: #F5F6FA !important;
}

#jdi-header, #jdi-footer {
  min-width: 1264px;
  margin: 0 auto;
}

.grecaptcha-badge {
  visibility: hidden;
}

body audio, canvas, embed, iframe, img, object, svg, video {
  display: inline;
}
